.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px;
}

.modal.right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.left.fade .modal-dialog {
  left: -500px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.modal.left.fade.show .modal-dialog {
  left: 0;
}

/* ----- MODAL STYLE ----- */
.modal-header {
  h5 {
    font-weight: $font-weight-bold;
  }
  padding: 0.85rem 1rem;
  border-radius: 0.5rem;
  border: 0;
}
.modal-content {
  border-radius: 0.5rem;
  border: 0;
}
.modal.right,
.modal.left {
  .close {
    color: #fff;
    text-shadow: none;
    outline: none;
  }
  .modal-header,
  .modal-content {
    border-radius: 0;
  }
  .modal-dialog {
    position: fixed;
    margin: auto;
    width: 600px;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
