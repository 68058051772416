$enable-shadows: true;

body,
html,
#root {
  font-family: 'Roboto', 'Open Sans', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  min-height: 100%;
  background-color: #fff;
}

.z-1 {
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.notification {
  position: relative;
  top: 0.65rem;
  right: -0.65rem;
}

.dropdown-toggle::after {
  display: none;
}

.arrow.dropdown-toggle::after {
  margin-top: 0.5em;
  display: block;
}

.nav.nav-tabs {
  & > .nav-item > .nav-link,
  & > .nav-item.nav-link {
    border: 0;
    background-color: inherit;
    &.active,
    &:hover {
      border: 0;
      border-bottom: 1px solid $primary;
      color: $link-color;
    }
  }
}

.table > thead > tr > th {
  border-top: 0;
  font-weight: 500;
}

.table-sm {
  font-size: 12px;
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-icon-lg {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 6px;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-notify {
  left: 0;
  border-radius: 2px;
  width: 100%;
  text-align: center;
  transition: opacity 0.5s;
}

.hide-animated {
  opacity: 0;
}

.mh-25 {
  max-height: 25rem;
  overflow: auto;
}

b,
strong {
  font-weight: 500;
}

.card {
  &.pointer-cursor {
    cursor: pointer;
  }
  &.bg-primary {
    .btn-link {
      color: #fff !important;
    }
  }
  @include box-shadow(
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12)
  );
  border-radius: 0.5rem;
  .card-header:first-child {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
  .dropdown-toggle {
    margin-top: -5px;
    border-radius: 50px;
    padding-left: 0;
    padding-right: 0;
    width: 2rem;
    height: 2rem;
    line-height: 1rem;
    i {
      margin-top: 0.125rem;
    }
    &:after {
      display: none;
    }
  }
  .list-group {
    border-top: 1px solid $gray-200;
  }
  .list-group-item {
    border-color: $gray-200;
  }
  .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  &.tenant-shortcuts {
    .list-group {
      border: 0;
    }
    .list-group-item {
      border: 0 !important;
    }
    .list-group-item:first-child {
      border-top-right-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
  }
}

.alert {
  border-radius: 6px;
  p:last-child {
    margin-bottom: 0;
  }
}

.footer {
  background-color: #29333d;
  color: #fff;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
}

.min-vh-100 {
  min-height: 100vh;
}