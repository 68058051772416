$yiq-contrasted-threshold: 200;
$body-bg: #f3f5f9;
$primary: #0062cc;
$success: #00c851;
$info: #33b5e5;
$danger: #f44;
$default: #b0bec5;
$warning: #fb3;
$light: #ebeef5;
$link-color: #0062cc;
$link-hover-color: #003d80;
$navbar-dark-color: #fff;
$navbar-dark-hover-color: #fff;
